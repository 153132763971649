import { Box, Button, Circle, HStack, Icon, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import Container from "../components/shared/Container";
import Image from "../components/shared/Image";
import { publicLayout } from "../components/shared/Layout";
import Link from "../components/shared/Link";
import Stack from "../components/shared/Stack";
import { Body2, Heading, SubHeading, LargeHeading } from "../components/shared/typography";
import { NextPageWithLayout } from "../types/Page";
import { useAccount } from "wagmi";
import { Users, UserWalletRole } from '@prisma/client';
import commonService from "../components/services/CommonService";
import useUser from "hooks/useUser";

const options = [
  {
    label: "I'm Employee",
    image: "/images/employee.png",
  },
  {
    label: "Organization",
    image: "/images/company.png",
  },
];

interface HomeProps { }

const Home: NextPageWithLayout<HomeProps> = (props) => {
  const [active, setActive] = useState(0);
  const router = useRouter();
  const { address } = useAccount();
  const { user } = useUser();

  return (
    <Box bg="primary">
      <Container>
        <Stack py="12" px="2" color="white" fontFamily="mulish" spacing={8}>
          <VStack
            align={{
              base: "center",
              lg: "flex-start",
            }}
            textAlign={{
              base: "center",
              lg: "left",
            }}
            spacing={8}
          >
            <LargeHeading fontWeight="light">Welcome to NESTED</LargeHeading>
            {!user?.isLoggedIn && (
              <>
                <SubHeading fontWeight="300" maxW="452px">
                  Apply to be featured and we&apos;ll highlight your profile to top
                  recruiters and companies searching for your specific skills.
                </SubHeading>
                <SubHeading fontWeight="300">
                  Oh, it&apos;s also 100% free
                </SubHeading>
              </>
            )}
            {user && user.isLoggedIn && <>
              <SubHeading fontWeight="300">
                Seems like you are new to the Nested club, No problem, you can build your profile by using the below button
              </SubHeading>
              <Link href="/">
                <Button
                  onClick={(e: any) => {
                    e.preventDefault();
                    return router.push("/register/candidate");
                  }}
                  bg="secondary"
                  w="296px"
                  h="48px"
                  color="white"
                >
                  Create Profile
                </Button>
              </Link>
            </>
            }

          </VStack>
          {!user?.isLoggedIn && (
            <Image maxW="575px" maxH="416px" src={"/images/recruite.png"} alt="" />
          )}
        </Stack>
      </Container>
    </Box>
  );
};

Home.getLayout = publicLayout;

export default Home;